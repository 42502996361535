import React from "react";
import styled from "styled-components";
import {FacebookIcon, InstagramIcon, XIcon, YoutubeIcon} from "../../../icons/Icons";

type IProps = {
    iconName: string;
    url: string;
    title: string;
    color?: string;
};

const iconMap: { [key: string]: React.FC<{ color?: string }> } = {
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    x: XIcon,
    youtube: YoutubeIcon,
};

const SocialMediaButton = ({
                               iconName,
                               url,
                               title,
                               color,
                           }: IProps) => {

    const IconComponent = iconMap[iconName.toLowerCase()];

    if (!IconComponent) {
        console.error(`Icon not found: ${iconName}`);
        return <div>Icon not found: {iconName}</div>;
    }

    return (
        <SocialMediaButtonWrapper key={Math.random()} href={url} target={'_blank'} rel={'noreferrer'} title={title}>
            <IconComponent color={color}/>
        </SocialMediaButtonWrapper>
    )
}

const SocialMediaButtonWrapper = styled.a`
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 47px;
    width: 47px;
`;

export default SocialMediaButton;
