export const PhotoIcon = () => {
    return (
        <svg width="95" height="125" viewBox="0 0 95 125" fill="none">
            <path
                d="M50.141 96.998C48.9624 96.998 47.6542 96.998 46.4884 96.8685C36.5668 95.9489 27.5519 91.1176 21.1533 83.4238C14.7548 75.7041 11.7498 65.912 12.6565 55.9904C13.5761 46.0688 18.4074 37.0538 26.1012 30.6553C33.8209 24.2567 43.613 21.2518 53.5346 22.1584C63.4562 23.0781 72.4712 27.9093 78.8697 35.6031C85.2683 43.3099 88.2732 53.102 87.3536 63.0236C86.434 72.9452 81.6027 81.9602 73.9089 88.3587C67.1218 93.9672 58.7545 96.9721 50.141 96.9721V96.998ZM50.0115 25.1634C42.3048 25.1634 34.598 27.7798 28.1995 32.9997C21.1533 38.7506 16.7106 46.9754 15.9205 56.1199C15.0009 65.2644 17.7468 74.1369 23.6273 81.196C29.5077 88.2421 37.603 92.6849 46.7475 93.475C55.892 94.3946 64.7644 91.6487 71.8236 85.7682C86.4469 73.7483 88.4028 52.0787 76.3958 37.4553C69.7382 29.36 59.8166 25.1764 50.0115 25.1764V25.1634Z"
                fill="black"/>
            <path
                d="M49.8802 66.5572C45.9685 66.5572 42.834 63.4227 42.834 59.511C42.834 55.5993 45.9685 52.4648 49.8802 52.4648C53.7918 52.4648 56.9263 55.5993 56.9263 59.511C56.9263 63.4227 53.7918 66.5572 49.8802 66.5572ZM49.8802 55.5864C47.7948 55.5864 46.098 57.2832 46.098 59.3685C46.098 61.4539 47.7948 63.1507 49.8802 63.1507C51.9655 63.1507 53.6623 61.4539 53.6623 59.3685C53.6623 57.2832 51.9655 55.5864 49.8802 55.5864Z"
                fill="black"/>
            <path
                d="M64.3748 72.829H35.3871C33.5608 72.829 32.123 71.3913 32.123 69.565V49.3202C32.123 47.4939 33.5608 46.0562 35.3871 46.0562H37.3429L38.2625 43.9708C38.6511 42.9217 39.7003 42.1445 40.8789 42.1445H47.148C48.3266 42.1445 49.3628 42.7922 49.7644 43.9708L50.684 46.0562H64.5302C66.3565 46.0562 67.7943 47.4939 67.7943 49.3202V69.4355C67.6647 71.2618 66.227 72.829 64.4007 72.829H64.3748ZM35.3871 49.3332V69.578H64.3748V49.3332H49.4924C48.8447 49.3332 48.1842 48.9446 47.9251 48.284L46.7464 45.4086H41.125L39.9464 48.284C39.6873 48.9316 39.1692 49.3332 38.3791 49.3332H35.3741H35.3871Z"
                fill="black"/>
            <circle cx="62" cy="22" r="22" fill="#D2147D" fillOpacity="0.1"/>
            <circle cx="46" cy="57" r="6" fill="#D2147D" fillOpacity="0.5"/>
            <circle cx="81" cy="43" r="14" fill="#D2147D" fillOpacity="0.4"/>
            <circle cx="31" cy="94" r="31" fill="#D2147D" fillOpacity="0.1"/>
        </svg>
    );
}

export const IconCircleCheck = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 16 16" fill="none">
            <path
                fill={'#18D14D'}
                d="M8 .5a7.5 7.5 0 100 15 7.5 7.5 0 000-15zm3.225 5.707l-3.427 4.5a.75.75 0 01-1.186.008l-1.83-2.332a.751.751 0 011.186-.923L7.19 9.02l2.835-3.75a.754.754 0 011.2.915v.022z"
            />
        </svg>
    );
};

export const IconClose = () => {

    return (
        <svg width="14" height="14" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#a)">
                <path
                    fill={'#ffffff'}
                    d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636l4.95 4.95z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h24v24H0z"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export const FacebookIcon = ({color = '#fff'}) => {
    return (
        <svg width="43" height="43" viewBox="0 0 47 47" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M23.0833 0C10.3348 0 0 10.3348 0 23.0833C0 35.8319 10.3348 46.1667 23.0833 46.1667C35.8319 46.1667 46.1667 35.8319 46.1667 23.0833C46.1667 10.3348 35.8319 0 23.0833 0ZM25.4892 24.0973V36.6558H20.2931V24.0978H17.6971V19.77H20.2931V17.1717C20.2931 13.6411 21.7589 11.5417 25.9236 11.5417H29.3908V15.8699H27.2236C25.6024 15.8699 25.4951 16.4747 25.4951 17.6035L25.4892 19.7695H29.4153L28.9559 24.0973H25.4892Z"
                  fill={color}/>
        </svg>

    );
}

export const InstagramIcon = ({color = '#fff'}) => {
    return (
        <svg width="43" height="43" viewBox="0 0 47 47" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M24.0278 0C11.2792 0 0.944458 10.3348 0.944458 23.0833C0.944458 35.8319 11.2792 46.1667 24.0278 46.1667C36.7764 46.1667 47.1111 35.8319 47.1111 23.0833C47.1111 10.3348 36.7764 0 24.0278 0ZM18.9528 10.8469C20.266 10.7871 20.6856 10.7725 24.0291 10.7725H24.0253C27.3698 10.7725 27.7879 10.7871 29.1011 10.8469C30.4117 10.9069 31.3069 11.1144 32.0917 11.4188C32.9022 11.733 33.587 12.1536 34.2718 12.8385C34.9566 13.5228 35.3773 14.2096 35.6928 15.0193C35.9954 15.8021 36.2032 16.6967 36.2647 18.0074C36.3237 19.3206 36.3391 19.7402 36.3391 23.0837C36.3391 26.4272 36.3237 26.8458 36.2647 28.159C36.2032 29.4691 35.9954 30.364 35.6928 31.1471C35.3773 31.9565 34.9566 32.6434 34.2718 33.3277C33.5878 34.0125 32.9019 34.4342 32.0925 34.7486C31.3092 35.0531 30.4135 35.2606 29.1029 35.3206C27.7897 35.3803 27.3714 35.395 24.0276 35.395C20.6843 35.395 20.265 35.3803 18.9518 35.3206C17.6414 35.2606 16.7465 35.0531 15.9632 34.7486C15.154 34.4342 14.4671 34.0125 13.7831 33.3277C13.0985 32.6434 12.6779 31.9565 12.3632 31.1468C12.059 30.364 11.8515 29.4694 11.7912 28.1588C11.7317 26.8456 11.7169 26.4272 11.7169 23.0837C11.7169 19.7402 11.7323 19.3203 11.791 18.0071C11.85 16.697 12.0577 15.8021 12.363 15.0191C12.6784 14.2096 13.0991 13.5228 13.7839 12.8385C14.4682 12.1539 15.155 11.7333 15.9648 11.4188C16.7475 11.1144 17.6422 10.9069 18.9528 10.8469Z"
                  fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M22.9248 12.9899C23.1393 12.9896 23.37 12.9897 23.6189 12.9898L24.0292 12.9899C27.3163 12.9899 27.7059 13.0017 29.004 13.0607C30.2043 13.1156 30.8558 13.3161 31.2898 13.4847C31.8643 13.7078 32.2739 13.9745 32.7046 14.4054C33.1354 14.8363 33.4022 15.2467 33.6258 15.8212C33.7944 16.2547 33.9952 16.9062 34.0498 18.1065C34.1088 19.4043 34.1216 19.7942 34.1216 23.0797C34.1216 26.3653 34.1088 26.7551 34.0498 28.053C33.9949 29.2533 33.7944 29.9048 33.6258 30.3382C33.4027 30.9128 33.1354 31.3218 32.7046 31.7525C32.2737 32.1834 31.8646 32.4501 31.2898 32.6733C30.8563 32.8425 30.2043 33.0426 29.004 33.0975C27.7062 33.1565 27.3163 33.1693 24.0292 33.1693C20.7419 33.1693 20.3523 33.1565 19.0545 33.0975C17.8541 33.0421 17.2027 32.8415 16.7684 32.673C16.1939 32.4499 15.7835 32.1831 15.3526 31.7522C14.9217 31.3213 14.655 30.912 14.4313 30.3372C14.2628 29.9037 14.062 29.2523 14.0074 28.0519C13.9484 26.7541 13.9366 26.3643 13.9366 23.0766C13.9366 19.789 13.9484 19.4012 14.0074 18.1034C14.0623 16.9031 14.2628 16.2516 14.4313 15.8176C14.6545 15.2431 14.9217 14.8327 15.3526 14.4018C15.7835 13.9709 16.1939 13.7042 16.7684 13.4805C17.2024 13.3113 17.8541 13.1112 19.0545 13.0561C20.1902 13.0048 20.6303 12.9894 22.9248 12.9868V12.9899ZM30.6006 15.0337C29.785 15.0337 29.1233 15.6947 29.1233 16.5105C29.1233 17.3262 29.785 17.9879 30.6006 17.9879C31.4162 17.9879 32.078 17.3262 32.078 16.5105C32.078 15.6949 31.4162 15.0332 30.6006 15.0332V15.0337ZM17.707 23.0816C17.707 19.5902 20.5377 16.7594 24.0291 16.7593C27.5206 16.7593 30.3507 19.5901 30.3507 23.0816C30.3507 26.5731 27.5209 29.4027 24.0294 29.4027C20.5378 29.4027 17.707 26.5731 17.707 23.0816Z"
                  fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M24.0288 18.9788C26.2951 18.9788 28.1325 20.816 28.1325 23.0825C28.1325 25.3488 26.2951 27.1863 24.0288 27.1863C21.7622 27.1863 19.925 25.3488 19.925 23.0825C19.925 20.816 21.7622 18.9788 24.0288 18.9788V18.9788Z"
                  fill={color}/>
        </svg>
    );
}

export const XIcon = ({color = '#fff'}) => {
    return (
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M21.556 0C9.77392 0 0.222656 9.55126 0.222656 21.3333C0.222656 33.1154 9.77392 42.6667 21.556 42.6667C33.3381 42.6667 42.8893 33.1154 42.8893 21.3333C42.8893 9.55126 33.3381 0 21.556 0ZM18.9358 22.688L10.0562 10H16.8997L22.7217 18.3145L29.9267 10H31.9378L23.6201 19.6006L33 33H26.1579L19.8348 23.9701L12.0111 32.9995H10L18.9358 22.688ZM16.1579 11.583H13.0138L26.8977 31.4165H30.0409L16.1579 11.583Z"
                  fill={color}/>
        </svg>
    );
}

export const YoutubeIcon = ({color = '#fff'}) => {
    return (
        <svg width="43" height="43" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M23.9167 0C11.1681 0 0.833374 10.3348 0.833374 23.0833C0.833374 35.8319 11.1681 46.1667 23.9167 46.1667C36.6653 46.1667 47 35.8319 47 23.0833C47 10.3348 36.6653 0 23.9167 0ZM33.5365 15.1484C34.5959 15.4391 35.4303 16.2957 35.7135 17.3835C36.228 19.3551 36.228 23.4687 36.228 23.4687C36.228 23.4687 36.228 27.5821 35.7135 29.5538C35.4303 30.6416 34.5959 31.4982 33.5365 31.7891C31.6165 32.3173 23.9169 32.3173 23.9169 32.3173C23.9169 32.3173 16.2173 32.3173 14.2972 31.7891C13.2377 31.4982 12.4033 30.6416 12.1202 29.5538C11.6058 27.5821 11.6058 23.4687 11.6058 23.4687C11.6058 23.4687 11.6058 19.3551 12.1202 17.3835C12.4033 16.2957 13.2377 15.4391 14.2972 15.1484C16.2173 14.6201 23.9169 14.6201 23.9169 14.6201C23.9169 14.6201 31.6165 14.6201 33.5365 15.1484Z"
                  fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.6083 27.6993V20.0049L27.7638 23.8523L21.6083 27.6993Z"
                  fill={color}/>
        </svg>

    );
}
